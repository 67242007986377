import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import ButtonPrimaryArrow from '../ButtonPrimaryArrow/ButtonPrimaryArrow';
import BottomItems from './BottomItems';
import styles from './Card.module.scss';

const CardBodyForEventList = ({
  advisor = {},
  tags,
  title,
  typeDisplay,
  pointCost,
  industriesDisplay = [],
  topicsDisplay = [],
  languagesDisplay = [],
  phaseDisplay,
  id,
  locationDisplay,
}) => {
  const { t } = useTranslation();

  const bottomProps = {
    locationDisplay,
    advisor,
    pointCost,
  };

  return (
    <>
      {!!advisor?.image?.file && (
        <div className={styles.ImageContainer}>
          <div
            className={styles.Image}
            style={{
              backgroundImage: `url(${advisor.picture.file})`,
            }}
          ></div>
        </div>
      )}
      <div className={styles.CardForEventListWrapper}>
        <div className={styles.Container}>
          <div className={styles.Top}>
            <div className={styles.Type}>{typeDisplay}</div>
            {tags?.map((tag) => (
              <div key={tag.name} className={styles.Tags}>
                {tag.name}
              </div>
            ))}
          </div>
          <div className={styles.Middle}>
            <div className={styles.Title}>
              <Link
                key={id}
                href={`/boka-tid/${id}/`}
                onClick={() => {
                  mtm.mtmPush({
                    event: 'activityClick',
                    activityType: typeDisplay,
                    industry: industriesDisplay.join(','),
                    subject: topicsDisplay.join(','),
                    place: locationDisplay,
                    phase: phaseDisplay,
                    language: languagesDisplay.join(','),
                  });
                }}
                className={styles.Title}
              >
                {title}
              </Link>
            </div>
          </div>

          <BottomItems
            items={['poi', 'advisor', 'point']}
            bottomProps={bottomProps}
          />

          <div className={styles.ButtonMoreInfo}>
            <ButtonPrimaryArrow
              isLink={true}
              href={`/boka-tid/${id}/`}
              label={t('EventsListCard.readMore')}
              mtmData={{
                event: 'activityClick',
                activityType: typeDisplay,
                industry: industriesDisplay.join(','),
                subject: topicsDisplay.join(','),
                place: locationDisplay,
                phase: phaseDisplay,
                language: languagesDisplay.join(','),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CardBodyForEventList;
