const DJANGO_URL = process?.env?.DJANGO_URL;

const LinkMapping = (namespace, props = {}, baseUrl) => {
  let url;

  if (!baseUrl) {
    baseUrl = DJANGO_URL;
  }

  switch (namespace) {
    case 'GLOBAL_SETTINGS':
      url = `${baseUrl}/api/starta_eget/v1/global_settings/`;
      break;
    case 'EVENT_COUNTS':
      url = `${baseUrl}/api/starta_eget/v1/events/counts/`;
      break;
    case 'CHECK_IF_ACTIVATED':
      url = `${baseUrl}/api/starta_eget/v1/check-if-activated/`;
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}?${qs.toString()}`;
      }
      break;
    case 'PAGE_CREATE_ACCOUNT':
      url = `${baseUrl}/api/starta_eget/v1/create_account_page/`;
      break;
    case 'ACCEPT_TOS':
      url = `${baseUrl}/api/starta_eget/v1/accept-tos/`;
      break;
    case 'INVOICE':
      url = `${baseUrl}/api/starta_eget/v1/invoices/list/`;
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}?${qs.toString()}`;
      }
      break;
    case 'INVOICE_MARK_REPORTED':
      url = `${baseUrl}/api/starta_eget/v1/invoices/set_invoiced/`;
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}?${qs.toString()}`;
      }
      break;
    case 'INVOICE_MARK_HANDLED':
      url = `${baseUrl}/api/starta_eget/v1/invoices/set_handled/`;
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}?${qs.toString()}`;
      }
      break;
    case 'INVOICE_MARK_HANDLED_BY_ID':
      url = `${baseUrl}/api/starta_eget/v1/invoices/set_handled_by_id/`;
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}?${qs.toString()}`;
      }
      break;
    case 'INVOICE_CSV':
      url = `${baseUrl}/api/starta_eget/v1/invoices/csv/`;
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}?${qs.toString()}`;
      }
      break;
    case 'INVOICE_XLSX':
      url = `${baseUrl}/api/starta_eget/v1/invoices/xlsx/`;
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}?${qs.toString()}`;
      }
      break;
    case 'STATISTICS_XLSX':
      console.log('props.qs', props.qs);
      url = `${baseUrl}/api/starta_eget/v1/statistics/xlsx/`;
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}?${qs.toString()}`;
      }
      break;
    case 'INVOICE_FILTERS':
      url = `${baseUrl}/api/starta_eget/v1/invoices/filters/`;
      break;
    case 'BOOKING_UPDATE_PRESENCE':
      url = `${baseUrl}/api/starta_eget/v1/bookings/update_presence/`;
      break;
    case 'BOOKING':
      url = `${baseUrl}/api/starta_eget/v1/bookings/`;

      if (props.past) {
        url = `${url}?past=1&page_size=50`;
      }
      if (props.wasPresent) {
        url = `${url}&reported=1`;
      }

      if (props.id) {
        url = `${url}${props.id}/`;
      }

      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}?${qs.toString()}`;
      }

      break;
    case 'BOOKING_UPDATE_PRESENCE':
      url = `${baseUrl}/api/starta_eget/v1/bookings/update_presence/`;
      break;
    case 'BOOKING_IS_USER_BOOKED':
      url = `${baseUrl}/api/starta_eget/v1/bookings/is_user_booked/?id=${props.eventId}`;
      break;
    case 'BOOKING_REVIEW':
      url = `${baseUrl}/api/starta_eget/v1/booking-reviews/`;
      if (props.id) {
        url = `${url}${props.id}/`;
      }
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}/?${qs.toString()}`;
      }
      break;
    case 'AUTHENTICATE':
      url = `${baseUrl}/api/starta_eget/v1/auth/`;
      break;
    case 'IS_AUTHENTICATED':
      url = `${baseUrl}/api/starta_eget/v1/is_authenticated/`;
      if (props.roleRequired) {
        url = `${url}?role=${props.roleRequired}`;
      }
      break;
    case 'ADVISOR_LISTING':
      url = `${baseUrl}/api/starta_eget/v1/profiles/`;
      url = `${url}?type=advisor&page_size=${props.pageSize || 500}`;
      break;
    case 'ADVISOR':
      url = `${baseUrl}/api/starta_eget/v1/profiles/`;
      if (props.id) {
        url = `${url}${props.id}/`;
      }
      if (props.supplier) {
        url = `${url}?supplier=${props.supplier}&page_size=${
          props.pageSize || 10
        }`;
      }
      if (props.sorting) {
        url = `${url}&sorting=${props.sorting}`;
      }
      if (props.search) {
        url = `${url}&search=${props.search}`;
      }
      break;
    case 'ADVISOR_INVITE':
      url = `${baseUrl}/api/starta_eget/v1/advisors/invite/`;
      break;
    case 'ADVISOR_ACTIVATE_ACCOUNT':
      url = `${baseUrl}/api/starta_eget/v1/advisors/activate-account/`;
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}/?${qs.toString()}`;
      }
      break;
    case 'ADVISOR_EDITABLE_FIELDS':
      url = `${baseUrl}/api/starta_eget/v1/user_editable_fields/`;
      break;
    case 'SUPPLIER':
      url = `${baseUrl}/api/starta_eget/v1/suppliers/`;
      if (props.pageSize) {
        url = `${url}?page_size=${props.pageSize}&sorting=name`;
      }
      if (props.id) {
        url = `${url}${props.id}/`;
      }
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}/?${qs.toString()}`;
      }

      break;
    case 'SUPPLIER_INVITE':
      url = `${baseUrl}/api/starta_eget/v1/suppliers/invite/`;
      break;
    case 'SUPPLIER_ACTIVATE_ACCOUNT':
      url = `${baseUrl}/api/starta_eget/v1/suppliers/activate-account/`;
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}/?${qs.toString()}`;
      }
      break;
    case 'LOGOUT':
      url = `${baseUrl}/api/starta_eget/v1/logout/`;
      break;
    case 'CSRF_TOKEN':
      url = `${baseUrl}/api/starta_eget/v1/get_csrf_token/`;
      break;
    case 'CRONOFY_GET_TOKEN':
      url = `${baseUrl}/api/starta_eget/v1/cronofy/get_token/`;
      if (props.eventId) {
        url = `${url}?event=${props.eventId}`;
      }
      break;
    case 'CRONOFY_BOOK_MEETING':
      url = `${baseUrl}/api/starta_eget/v1/cronofy/book_meeting/`;
      break;
    case 'CRONOFY_UNBOOK_MEETING':
      url = `${baseUrl}/api/starta_eget/v1/cronofy/unbook_meeting/`;
      break;
    case 'SETTINGS':
      url = `${baseUrl}/api/starta_eget/v1/settings/`;
      break;
    case 'EVENT':
      url = `${baseUrl}/api/starta_eget/v1/events/`;
      if (props.id) {
        url = `${url}${props.id}/`;
      }
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}?${qs.toString()}`;
      }
      break;
    case 'EVENT_WORKSHOP_DATES':
      url = `${baseUrl}/api/starta_eget/v1/events/workshop-dates/?id=${props.eventId}`;
      break;
    case 'EVENT_ADVISORY_DATES':
      url = `${baseUrl}/api/starta_eget/v1/events/advisory-dates/?id=${props.eventId}`;
      break;
    case 'EVENT_FILTER':
      url = `${baseUrl}/api/starta_eget/v1/events/filters/`;
      if (props.qs) {
        const qs = new URLSearchParams(props.qs);
        url = `${url}?${qs.toString()}`;
      }
      break;
    case 'USER_SETTINGS':
      url = `${baseUrl}/api/starta_eget/v1/user_settings/`;
      break;
    case 'PROFILE':
      url = `${baseUrl}/api/starta_eget/v1/profiles/`;
      if (props.id) {
        url = `${url}${props.id}/`;
      }
      break;
    case 'RESET_PASSWORD':
      url = `${baseUrl}/api/starta_eget/v1/reset-password/`;
      break;
    case 'CHANGE_PASSWORD_BY_RESET':
      url = `${baseUrl}/api/starta_eget/v1/change-password-by-reset/`;
      break;
    default:
      throw `Unknown namespace ${namespace}`;
  }

  return url;
};

export default LinkMapping;
