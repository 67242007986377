import { useTranslation } from 'next-i18next';
import { Rating } from 'react-simple-star-rating';
import EmptyStar from '../../public/img/icon-review-deselected.svg';
import FillStar from '../../public/img/icon-review-selected.svg';
import styles from './Card.module.scss';

const CardBodyForAdvisorReview = ({
  tags,
  title,
  typeDisplay,
  review = {},
}) => {
  const rating = (review?.rating || 0) * 20;
  const comment = review?.comment || null;
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.BodyContainer}>
        <div className={styles.CardForAdvisorReviewWrapper}>
          <div className={styles.Container}>
            <div className={styles.Top}>
              <div className={styles.Type}>{typeDisplay}</div>
              {tags?.map((tag) => (
                <div key={tag.name} className={styles.Tags}>
                  {tag.name}
                </div>
              ))}
            </div>
            <div className={styles.Middle}>
              <div className={styles.Title}>{title || ''}</div>
            </div>
          </div>
          <div className={styles.RatingContainer}>
            <div className={styles.RatingWrapper}>
              <div className={styles.RatingLabel}>
                {t('EventsListCardReview.review')}
              </div>
              <Rating
                className={styles.RatingClass}
                ratingValue={rating || 0}
                initialValue={rating || 0}
                iconsCount={5}
                fullIcon={<FillStar />}
                emptyIcon={<EmptyStar />}
                transition
                readonly={true}
              />
            </div>
          </div>
        </div>
        {!!comment && (
          <div className={styles.TextAreaWrapper}>
            <div className={styles.TextareaAdvisor}>{comment}</div>
            <span className={styles.TextAreaLabelAdvisor}>
              {t('EventsListCardReview.textAreaLabelForAdvisor')}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default CardBodyForAdvisorReview;
