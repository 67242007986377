import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import Icon from '../Icon';
import BottomItems from './BottomItems';
import styles from './Card.module.scss';

const CardBodyForAttendance = ({
  advisor = {},
  tags,
  title,
  start,
  time,
  typeDisplay,
  user = {},
  wasPresent,
  hovercard,
  id,
  locationDisplay,
}) => {
  const { t } = useTranslation();

  const bottomProps = {
    time,
    start,
    locationDisplay,
    user,
    hovercard,
  };

  return (
    <>
      <div className={styles.CardForAttendanceWrapper}>
        {/* Byt tillbaka till user här  */}
        {!!advisor.picture?.url && (
          <div className={styles.ImageContainer}>
            <div
              className={styles.Image}
              style={{
                backgroundImage: `url(${advisor.picture?.url})`,
              }}
            ></div>
          </div>
        )}
        <div className={styles.Container}>
          <div className={styles.Top}>
            <div className={styles.Type}>{typeDisplay}</div>
            {tags?.map((tag) => (
              <div key={tag.name} className={styles.Tags}>
                {tag.name}
              </div>
            ))}

            <div className={styles.PresentContainerMobile}>
              <Icon
                src={wasPresent ? '/img/icon-check.svg' : '/img/icon-x-red.svg'}
                width={24}
                height={24}
              />
              <span className={styles.PresentLabelMobile}>
                {wasPresent
                  ? t('EventsListCard.present')
                  : t('EventsListCard.absent')}
              </span>
            </div>
          </div>
          <div className={styles.Middle}>
            <div className={styles.Title}>
              <Link key={id} href={`/boka-tid/${id}/`} className={styles.Title}>
                {title}
              </Link>
            </div>
          </div>

          <BottomItems
            items={['calender', 'time', 'poi', 'user', 'email']}
            bottomProps={bottomProps}
          />
        </div>
        <div className={styles.PresentContainerDesktop}>
          <Icon
            src={wasPresent ? '/img/icon-check.svg' : '/img/icon-x-red.svg'}
            width={24}
            height={24}
          />
          <span className={styles.PresentLabelDesktop}>
            {wasPresent
              ? t('EventsListCard.present')
              : t('EventsListCard.absent')}
          </span>
        </div>
      </div>
    </>
  );
};

export default CardBodyForAttendance;
