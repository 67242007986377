import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import TertiaryButton from '../TertiaryButton/TertiaryButton';
import BottomItems from './BottomItems';
import CancelButton from './CancelButton';
import styles from './Card.module.scss';

const CardBodyForAdvisor = ({
  tags,
  hovercard,
  title,
  pointCost,
  start,
  time,
  meetingLink,
  typeDisplay,
  user = {},
  showButton,
  bookingId,
  id,
  locationDisplay,
}) => {
  const { t } = useTranslation();

  const bottomItems = [
    'calender',
    'time',
    'poi',
    'user',
    'meetingLink',
    'point',
  ];
  const bottomProps = {
    time,
    start,
    meetingLink,
    locationDisplay,
    user,
    pointCost,
    hovercard,
  };

  return (
    <>
      <div className={styles.CardForAdvisorWrapper}>
        {!!user?.picture?.file && (
          <div className={styles.ImageContainer}>
            <div
              className={styles.Image}
              style={{
                backgroundImage: `url(${user.picture.file})`,
              }}
            ></div>
          </div>
        )}
        <div className={styles.Container}>
          <div className={styles.Top}>
            <div className={styles.Type}>{typeDisplay}</div>
            {tags?.map((tag) => (
              <div key={tag.name} className={styles.Tags}>
                {tag.name}
              </div>
            ))}
          </div>
          <div className={styles.Middle}>
            <div className={styles.Title}>
              <Link key={id} href={`/boka-tid/${id}/`} className={styles.Title}>
                {title}
              </Link>
            </div>
          </div>
          <BottomItems items={bottomItems} bottomProps={bottomProps} />
          <div className={styles.MobileLinksContainer}>
            <TertiaryButton
              text={t('EventsListCard.moreInfo')}
              href={`/boka-tid/${id}/`}
              isButton={false}
            />
            {showButton && (
              <TertiaryButton
                text={t('EventsListCard.cancelTime')}
                href={`/avboka-tid/${bookingId}/`}
                isButton={false}
              />
            )}
          </div>
          <div className={styles.DesktopLinksContainer}>
            {showButton && <CancelButton bookingId={bookingId} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardBodyForAdvisor;
