import styles from './CancelButton.module.scss';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

const CancelButton = ({ bookingId }) => {
  const { t } = useTranslation();

  return (
    <Link href={`/avboka-tid/${bookingId}/`} legacyBehavior>
      <a className={styles.Button}>
        {t('EventsListCard.cancelTime')}
        <span className={styles.Arrow} aria-hidden={true} />
      </a>
    </Link>
  );
};

export default CancelButton;
