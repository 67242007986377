import classNames from 'classnames';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import EmptyStar from '../../public/img/icon-review-deselected.svg';
import FillStar from '../../public/img/icon-review-selected.svg';
import LinkMapping from '../../utils/links';
import BottomItems from './BottomItems';
import styles from './Card.module.scss';

const CardBodyForReview = ({
  advisor = {},
  tags,
  title,
  start,
  time,
  meetingLink,
  typeDisplay,
  djangoUrl,
  review = {},
  bookingId,
  id, // eventId
  pointCost,
  locationDisplay,
  status,
}) => {
  const [rating, setRating] = useState((review?.rating || 0) * 20);
  const [isClicked, setIsClicked] = useState(!!review?.comment || !!review?.id);
  const [comment, setComment] = useState(review?.comment || null);
  // const [reviewId, setReviewId] = useState(review?.id || null);
  const [reviewId, setReviewId] = useState(review?.id || null);
  const [textareaExpanded, setTextareaExpanded] = useState(!!review?.comment);
  const { t } = useTranslation();

  const bottomItems = ['calender', 'time', 'poi', 'advisor', 'point'];
  const bottomProps = {
    time,
    start,
    locationDisplay,
    advisor,
    pointCost,
  };

  const wasPresent = status === 1 || status == undefined;
  const wasAbsent = status === 2;
  const wasUnreported = status === 0;

  const submitButtonStyles = classNames(styles.SubmitButton, {
    [styles.SubmitButtonClicked]: isClicked,
    [styles.HasComment]: !!comment,
  });

  const reviewUrl = LinkMapping('BOOKING_REVIEW', {}, djangoUrl);

  const commitRating = async (value) => {
    const realRating = parseInt(value / 20, 10);

    setRating(rating);

    const response = await fetch(reviewUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      body: JSON.stringify({
        booking_id: bookingId,
        rating: realRating,
      }),
      credentials: 'same-origin',
    });

    const reviewData = await response.json();
    setReviewId(reviewData.id);
    setTextareaExpanded(true);
  };

  const commitComment = async () => {
    const commentUrl = LinkMapping(
      'BOOKING_REVIEW',
      { id: reviewId },
      djangoUrl
    );
    const response = await fetch(commentUrl, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      body: JSON.stringify({
        comment: comment,
      }),
      credentials: 'same-origin',
    });

    if (response.ok) {
      setIsClicked(true);
    }

    setTextareaExpanded(true);
  };

  const hasNoComment = !!reviewId && !review?.comment;

  return (
    <>
      <div className={styles.BodyContainer}>
        <div className={styles.CardForReviewWrapper}>
          {!!advisor?.image?.file && (
            <div className={styles.ImageContainer}>
              <div
                className={styles.Image}
                style={{
                  backgroundImage: `url(${advisor.picture.file})`,
                }}
              ></div>
            </div>
          )}
          <div className={styles.RatingContainer}>
            <div className={styles.RatingWrapper}>
              {wasPresent && (
                <>
                  <div className={styles.RatingLabel}>
                    {!reviewId
                      ? t('EventsListCardReview.label')
                      : t('EventsListCardReview.labelSubmitted')}
                  </div>
                  <div className={styles.RatingAnonymous}>
                    {t('EventsListCardReview.labelAnonymous')}
                  </div>
                  <Rating
                    className={styles.RatingClass}
                    initialValue={rating || 0}
                    ratingValue={rating || 0}
                    iconsCount={5}
                    fullIcon={<FillStar />}
                    emptyIcon={<EmptyStar />}
                    transition
                    onClick={commitRating}
                    readonly={rating > 0}
                  />
                </>
              )}
              {(wasAbsent || wasUnreported) && (
                <div className={styles.NotAvailable}>
                  <div className={styles.RatingLabel}>
                    {t('EventsListCardReview.label')}
                  </div>
                  <div className={styles.RatingNotAvailableText}>
                    {wasAbsent
                      ? t('EventsListCardReview.wasAbsent')
                      : t('EventsListCardReview.wasUnreported')}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.Container}>
            <div className={styles.Top}>
              <div className={styles.Type}>{typeDisplay}</div>
              {tags?.map((tag) => (
                <div key={tag.name} className={styles.Tags}>
                  {tag.name}
                </div>
              ))}
            </div>
            <div className={styles.Middle}>
              <div className={styles.Title}>
                <button
                  onClick={() => setTextareaExpanded((pv) => !pv)}
                  className={styles.TitleButton}
                >
                  {title}
                </button>
              </div>
            </div>
            <BottomItems items={bottomItems} bottomProps={bottomProps} />
          </div>
          {wasPresent && textareaExpanded && reviewId && (
            <div className={styles.TextAreaWrapper}>
              <textarea
                id={`tarea_${id}`}
                defaultValue={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder=""
                disabled={isClicked}
                className={styles.Textarea}
              />
              <label htmlFor={`tarea_${id}`} className={styles.TextAreaLabel}>
                {t('EventsListCardReview.textAreaLabel')}
              </label>
              <div className={styles.SubmitButtonWrapper}>
                <button
                  type="button"
                  className={submitButtonStyles}
                  disabled={isClicked}
                  onClick={() => {
                    commitComment();
                  }}
                >
                  {!isClicked
                    ? t('EventsListCardReview.submit')
                    : t('EventsListCardReview.submitted')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CardBodyForReview;
