import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import TertiaryButton from '../TertiaryButton/TertiaryButton';
import BottomItems from './BottomItems';
import CancelButton from './CancelButton';
import styles from './Card.module.scss';

const CardBodyForUser = ({
  advisor = {},
  tags,
  title,
  start,
  time,
  meetingLink,
  typeDisplay,
  showButton,
  bookingId,
  locationDisplay,
  id,
}) => {
  const { t } = useTranslation();

  const bottomProps = { time, start, meetingLink, locationDisplay, advisor };

  return (
    <>
      {!!advisor?.image?.file && (
        <div className={styles.ImageContainer}>
          <div
            className={styles.Image}
            style={{
              backgroundImage: `url(${advisor.picture.file})`,
            }}
          ></div>
        </div>
      )}
      <div className={styles.CardForUserWrapper}>
        <div className={styles.Container}>
          <div className={styles.Top}>
            <div className={styles.Type}>{typeDisplay}</div>
            {tags?.map((tag) => (
              <div key={tag.name} className={styles.Tags}>
                {tag.name}
              </div>
            ))}
          </div>
          <div className={styles.Middle}>
            <div className={styles.Title}>
              <Link key={id} href={`/boka-tid/${id}/`} className={styles.Title}>
                {title}
              </Link>
            </div>
          </div>
          <BottomItems
            items={['calender', 'time', 'poi', 'advisor', 'meetingLink']}
            bottomProps={bottomProps}
          />

          <div className={styles.DesktopLinksContainer}>
            <CancelButton bookingId={bookingId} />
          </div>

          <div className={styles.MobileLinksContainer}>
            <TertiaryButton
              text={t('EventsListCard.moreInfo')}
              href={`/boka-tid/${id}/`}
              isButton={false}
            />
            <TertiaryButton
              text={t('EventsListCard.cancelTime')}
              href={`/avboka-tid/${bookingId}/`}
              isButton={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CardBodyForUser;
