import { HovercardAnchor } from 'ariakit/hovercard';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './Card.module.scss';

const BottomItems = ({ items = [], bottomProps }) => {
  return (
    <div className={`${styles.NewBottomItems}`}>
      {items.map((type, index) => {
        if (type === 'advisor') {
          return <AdvisorItem key={index} {...bottomProps} />;
        }
        if (type === 'meetingLink') {
          return <MeetingLink key={index} {...bottomProps} />;
        }
        if (type === 'user') {
          return <UserItem key={index} {...bottomProps} />;
        }
        if (type === 'email') {
          return <EmailItem key={index} {...bottomProps} />;
        }
        return <SimpleItem key={index} type={type} {...bottomProps} />;
      })}
    </div>
  );
};

const UserItem = ({ hovercard, user }) => {
  let name = user?.firstName;
  if (user?.lastName) {
    name += ` ${user.lastName}`;
  }
  console.log(hovercard);

  return (
    <HovercardAnchor
      className={`${styles.UserItem} ${styles.NewBottomItem}`}
      state={hovercard}
    >
      <Image
        src="/img/icon-person.svg"
        height={19.8}
        width={20.3}
        alt="Person Icon"
        className={styles.Icons}
      />
      <p
        className={`${styles.NewBottomText} ${styles.User}`}
        onClick={() => {
          hovercard.toggle();
        }}
      >
        {name || user?.email || user?.phoneNumber}
      </p>
    </HovercardAnchor>
  );
};

const SimpleItem = ({ type, time, start, locationDisplay, pointCost }) => {
  const { t } = useTranslation();

  const textMapping = {
    time: time,
    calender: start,
    poi: locationDisplay,
    point: `${pointCost} ${t('EventsListCard.points')}`,
  };

  return (
    <div className={styles.NewBottomItem}>
      <Image
        src={`/img/icon-${type}.svg`}
        height={19.8}
        width={20.3}
        alt=""
        className={styles.NewIcons}
      />
      <p className={styles.NewBottomText}>{textMapping[type]}</p>
    </div>
  );
};

const MeetingLink = ({ meetingLink }) => {
  const { t } = useTranslation();

  if (!meetingLink) {
    return null;
  }

  return (
    <div className={styles.NewBottomItem}>
      <Image
        src="/img/icon-link.svg"
        height={19.8}
        width={20.3}
        alt=""
        className={styles.NewIcons}
      />
      <div className={styles.NewBottomText}>
        <a href={meetingLink} className={styles.NewLink}>
          {t('EventsListCard.meet')}
        </a>
      </div>
    </div>
  );
};

const EmailItem = ({ user }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.NewBottomItem}>
      <Image
        src="/img/icon-mail.svg"
        height={19.8}
        width={20.3}
        alt=""
        className={styles.NewIcons}
      />
      <div className={styles.NewBottomText}>
        <a href={`mailto:${user.email}`} className={styles.NewLink}>
          {t('EventsListCard.email')}
        </a>
      </div>
    </div>
  );
};

const AdvisorItem = ({ advisor }) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.Advisor} ${styles.NewBottomItem}`}>
      <Image
        src="/img/icon-person.svg"
        height={19.8}
        width={20.3}
        alt=""
        className={styles.NewIcons}
      />
      <p className={styles.NewBottomText}>
        {!!advisor?.firstName && (
          <Link href={`/radgivare/${advisor.id}/`} className={styles.NewLink}>
            {advisor.firstName}
          </Link>
        )}{' '}
        {t('EventsListCard.from')}{' '}
        <Link
          href={`/leverantor/${advisor.supplier?.id}/`}
          className={styles.NewLink}
        >
          {advisor.supplier?.name}
        </Link>
      </p>
    </div>
  );
};

export default BottomItems;
