import {
  Hovercard,
  HovercardArrow,
  HovercardHeading,
  useHovercardState,
} from 'ariakit/hovercard';
import classNames from 'classnames';
import styles from './Card.module.scss';
import CardBodyForAdvisor from './CardBodyForAdvisor';
import CardBodyForAdvisorReview from './CardBodyForAdvisorReview';
import CardBodyForAttendance from './CardBodyForAttendance';
import CardBodyForEventList from './CardBodyForEventList';
import CardBodyForReview from './CardBodyForReview';
import CardBodyForUser from './CardBodyForUser';

// CardForUser - shows date, time, advisor, supplier, meeting link - unbook button
// CardForAdvisor - shows date, time, user, meeting link - unbook button
// CardForEventList - shows occurrences, advisor, etc - more info button
// CardForAttendance - date, time, user, etc - no button but checkbox
//
// CardForUser - https://app.zeplin.io/project/62726674a9f5881623e3c444/styleguide/components?coid=627267e45c866d16f85e6580
// CardForAdvisor - https://app.zeplin.io/project/62726674a9f5881623e3c444/styleguide/components?coid=62bb04769526d41104ec2c67
// CardForEventList - https://app.zeplin.io/project/62726674a9f5881623e3c444/styleguide/components?coid=62972f67a45e5816690ff436
// CardForAttendance - https://app.zeplin.io/project/62726674a9f5881623e3c444/styleguide/components?coid=62bb047415386913eb87ac2c

const Card = ({
  cardType,
  formattedDate,
  status,
  formattedTime,
  event = {},
  user = {},
  wasPresent,
  showButton = true,
  djangoUrl,
  showAdvisor = false,
  review = {},
  meetingLink,
  id,
}) => {
  const hovercard = useHovercardState({ gutter: 16, timeout: 0 });
  const bodyClasses = classNames(styles.Body, {
    [styles.BodyReview]: cardType === 'review' || cardType === 'advisor-review',
    [styles.BodyAdvisorReview]: cardType === 'advisor-review',
  });
  return (
    <div className={styles.Root}>
      <div className={bodyClasses}>
        {cardType === 'user' && (
          <CardBodyForUser
            {...event}
            bookingId={id}
            start={formattedDate}
            meetingLink={meetingLink}
            time={formattedTime}
            showButton={showButton}
          />
        )}
        {cardType === 'advisor' && (
          <CardBodyForAdvisor
            {...event}
            user={user}
            bookingId={id}
            hovercard={hovercard}
            start={formattedDate}
            time={formattedTime}
            meetingLink={meetingLink}
            showButton={showButton}
          />
        )}
        {cardType === 'eventList' && <CardBodyForEventList {...event} />}
        {cardType === 'attendance' && (
          <CardBodyForAttendance
            start={formattedDate}
            bookingId={id}
            wasPresent={wasPresent}
            hovercard={hovercard}
            {...event}
            user={user}
            time={formattedTime}
          />
        )}
        {cardType === 'review' && (
          <CardBodyForReview
            start={formattedDate}
            time={formattedTime}
            wasPresent={wasPresent}
            review={review}
            djangoUrl={djangoUrl}
            status={status}
            {...event}
            bookingId={id}
          />
        )}
        {cardType === 'advisor-review' && (
          <CardBodyForAdvisorReview
            start={formattedDate}
            time={formattedTime}
            wasPresent={wasPresent}
            review={review}
            djangoUrl={djangoUrl}
            showAdvisor={showAdvisor}
            {...event}
            bookingId={id}
          />
        )}
      </div>
      <Hovercard state={hovercard} portal={true} className={styles.Popover}>
        <HovercardArrow className={styles.Arrow} />
        <HovercardHeading className={styles.HoverHeading}>
          {user?.firstName || user?.email}
        </HovercardHeading>
        <ul className={styles.Ul}>
          <li className={styles.Li}>
            Namn: {user?.firstName} {user?.lastName}
          </li>
          <li className={styles.Li}>E-post: {user?.email}</li>
          <li className={styles.Li}>Telefonnummer: {user?.phoneNumber}</li>
        </ul>
      </Hovercard>
    </div>
  );
};

Card.propTypes = {};

Card.defaultProps = {};

export default Card;
