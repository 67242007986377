import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';
import styles from './TertiaryButton.module.scss';
import Link from 'next/link';

const TertiaryButton = ({ id, text, isButton, href = '', ...props }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.Root}>
      {!isButton ? (
        <div className={styles.TertiaryLinkContainer}>
          <Link key={id} href={href} {...props} className={styles.TertiaryLink}>
            {text}
          </Link>
        </div>
      ) : (
        <div className={styles.TertiaryButtonContainer}>
          <button className={styles.Button} {...props}>
            <div className={styles.TertiaryButton}>{text}</div>
          </button>
        </div>
      )}
    </div>
  );
};

TertiaryButton.propTypes = {};

TertiaryButton.defaultProps = {};

// export default basePageWrap(TertiaryButton);
export default TertiaryButton;
