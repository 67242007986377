// import { useTranslation } from 'next-i18next'
// import PropTypes from 'prop-types';
// import { basePageWrap } from '../BasePage';
import styles from './ButtonPrimaryArrow.module.scss';
import Link from 'next/link';
import classNames from 'classnames';
import * as mtm from '../../utils/mtm';

const ButtonPrimaryArrow = ({
  label,
  isLink = false,
  primaryRadius,
  mtmData,
  ...props
}) => {
  const classes = classNames(styles.ButtonMoreInfo, {
    [styles.PrimaryRadius]: primaryRadius,
  });

  const extraProps = {};
  if (!!mtmData) {
    extraProps.onClick = () => {
      mtm.mtmPush(mtmData);
      if (props.onClick) {
        props.onClick();
      }
    };
  }

  return (
    <div className={styles.Root}>
      {isLink ? (
        <Link {...props} className={classes}>
          <span className={styles.MoreInfo}>
            {label}
            <span className={styles.ArrowWhite} aria-hidden={true}></span>
          </span>
        </Link>
      ) : (
        <button className={classes} {...props} {...extraProps}>
          <span className={styles.MoreInfo}>
            {label}
            <span className={styles.ArrowWhite} aria-hidden={true}></span>
          </span>
        </button>
      )}
    </div>
  );
};

ButtonPrimaryArrow.propTypes = {};

ButtonPrimaryArrow.defaultProps = {};

// export default basePageWrap(ButtonPrimaryArrow);
export default ButtonPrimaryArrow;
